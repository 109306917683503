import React from 'react';
import './Basic.css';
import maps from '../../assets/img/maps.png'
import { Container, Row, Col, Carousel } from 'react-bootstrap';

function importAll(r) {
  return r.keys().map(r);
}

const images2 = importAll(require.context('../../assets/img/slider-dos/', false, /\.(png|jpe?g|svg)$/));

function Basic() {
  return (
    <Container fluid className="relative">

      <Container className="section-estandar">
        <Row>
          <Col md="7">
              <Carousel className="top-slider" controls={false}>
                
                { images2.map((image, indx) => {
                                return (
                                    <Carousel.Item key={indx} ><img  className="d-block w-100" src={image} alt="Valenzza" /></Carousel.Item>
                                )
                            })}
                </Carousel>
          </Col>
          <Col md="5" className="text-justify fore-gray mtop40 center-vertical">
            <p><b>VALENZZA CONDOMINIO</b> es un proyecto diseñado para quienes buscan elegir más.</p>

            <p>Aquí los espacios, acabados y detalles están inspirados en el confort que transmiten las grandes zonas verdes que lo rodean; fusionándose con la elegancia de un exclusivo sector comercial y empresarial en desarrollo.</p>

            <Row>
            <a href="https://www.google.com.co/maps/search/m+2.0+Anillo+vial,+v%C3%ADa+Floridablanca+-+Gir%C3%B3n/@7.0613639,-73.1124122,19z?hl=es-419" target="_blank" rel="noopener noreferrer" className="img-maps"><img src={maps} alt="Conoce la zona" /></a>
            </Row>
          </Col>
        </Row>

      </Container>

    </Container>
  );
}

export default Basic;
