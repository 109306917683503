import React from 'react';
import './Proyecto.css';
import edificio from '../../assets/img/edificio.png'
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import leafRight from '../../assets/img/right-leaf.png'
import flor from '../../assets/img/title-flower.png';


function importAll(r) {
    return r.keys().map(r);
}

const images = importAll(require.context('../../assets/img/slider/', false, /\.(png|jpe?g|svg)$/));
const icons = importAll(require.context('../../assets/img/icons/', false, /\.(png|jpe?g|svg)$/));

const Proyecto = (props) => {

    

  return (
    <Container fluid className="relative back-leaf">
      <img src={leafRight} className="leafRight" alt="hojaderecha" />
      <Container className="section-estandar">
        <Row className="amenidades">
            <Col md={12}>
                <h2 className="title-seccion">Amenidades</h2>
            </Col>
           <Col md={6} sm={12}>
                <Row className="icons">
                    <Col md={2} sm={3} xs={3}>
                        <img
                            className="d-block w-100"
                            src={icons[0]}
                            alt="valenzza"
                        />
                    </Col>
                    <Col md={2} sm={3} xs={3}>
                        <img
                            className="d-block w-100"
                            src={icons[1]}
                            alt="valenzza"
                        />
                     </Col>
                    <Col md={2} sm={3} xs={3}>
                        <img
                            className="d-block w-100"
                            src={icons[2]}
                            alt="valenzza"
                        />
                     </Col>
                    <Col md={2} sm={3} xs={3}>
                        <img
                            className="d-block w-100"
                            src={icons[3]}
                            alt="valenzza"
                        />
                      </Col>
                    <Col md={2} sm={3} xs={3}>
                        <img
                            className="d-block w-100"
                            src={icons[4]}
                            alt="valenzza"
                        />
                      </Col>
                    <Col md={2} sm={3} xs={3}>
                        <img
                            className="d-block w-100"
                            src={icons[5]}
                            alt="valenzza"
                        />
                      </Col>                  
                    <Col md={2} sm={3} xs={3}>
                        <img
                            className="d-block w-100"
                            src={icons[6]}
                            alt="valenzza"
                        />
                      </Col>
                    <Col md={2} sm={3} xs={3}>
                        <img
                            className="d-block w-100"
                            src={icons[7]}
                            alt="valenzza"
                        />
                      </Col>
                    <Col md={2} sm={3} xs={3}>
                        <img
                            className="d-block w-100"
                            src={icons[8]}
                            alt="valenzza"
                        />
                      </Col>
                    <Col md={2} sm={3} xs={3}>
                        <img
                            className="d-block w-100"
                            src={icons[9]}
                            alt="valenzza"
                        />
                      </Col>
                    <Col md={2} sm={3} xs={3}>
                        <img
                            className="d-block w-100"
                            src={icons[10]}
                            alt="valenzza"
                        />
                      </Col>
                    <Col md={2} sm={3} xs={3}>
                        <img
                            className="d-block w-100"
                            src={icons[11]}
                            alt="valenzza"
                        />
                    </Col>
                    </Row>          
            </Col>
            <Col md={6} sm={12}>
            <Carousel id="slideedificio" controls={false}>
                
            { images.map((image, indx) => {
                            return (
                                <Carousel.Item key={indx} ><img  className="d-block w-100" src={image} alt="valenzza" /></Carousel.Item>
                            )
                        })}
            </Carousel>
            </Col>
           
        </Row>
      </Container>
      <Container >
          <Row>
                <Col md={6} sm={6} xs={12}>
                    <img src={flor} className="flores-top" alt="Símbolo valenzza" />
                    <img src={edificio} className="edificio" alt="Edificio valenzza" />
                </Col>
                <Col md={6} sm={6} xs={12} className="implantacion">
                    <h3 className="thin text-center">ETAPAS <span className="bold">CONSTRUCTIVAS</span></h3>
                    <br/>
                   
                    <Row>
                        <Col md={6} sm={12}>
                        <h5>ETAPA<span className="bold"> UNO</span></h5>
                        <ul className="punto">
                        <li className="bold"><i class="fa fa-circle"></i> TORRE UNO</li>
                        <li>LOBBY LIVING GREEN</li>
                        <li>ZONA DE PARQUEOS</li>
                        <li>PARQUEO PARA BICICLETAS</li>
                        <li>CAMINODROMO</li>
                        <li>MEETING ROOM</li>
                        <li>CO-WORKING</li>
                        <li>SALÓN DE ESTUDIOS</li>
                        <li>SKYBAR</li>
                        <li>CANCHA SINTÉTICA</li>
                        <li>BAR B.Q DECK</li>
                        <li className="bold"><i class="fa fa-circle"></i> ZONA HÚMEDA</li>
                        <li>PISCINA INFINITA, JACUZZI, HIDROMASAJES,</li>
                        <li>SOLARIUM HÚMEDO, RELAX ZONE, TURCO</li>
                        <li className="bold"><i class="fa fa-circle"></i> ZONA DE JUEGOS INFANTILES</li>
                        <li>MINIGOLF, PLAYGROUND</li>
                        <li>TRICIRUTA</li>
                        <li>TERRAZA DECK GAMER</li>
                        <li>SALÓN DE EVENTOS</li>
                    </ul>
                    
                        </Col>
                        <Col md={6} sm={12}>
                        <h5>ETAPA<span className="bold"> DOS</span></h5>
                    <ul className="punto">
                        <li className="bold"><i class="fa fa-circle"></i> TORRE DOS</li>
                        <li>LOCALES</li>
                        <li>CANCHA DE SQUASH</li>
                        <li>PET PARK</li>
                        <li>CANCHA MULTIFUNCIONAL</li>
                        <li className="bold"><i class="fa fa-circle"></i> ZONA HÚMEDA</li>
                        <li>PISCINA PARA NIÑOS, SPLASH ZONE</li>
                        <li>TERRAZA DE LECTURA</li>
                        <li>GAMER ZONE</li>
                        <li>JUEGOS DE MESA</li>
                        <li>CINEMA</li>
                        <li className="bold"><i class="fa fa-circle"></i> ZONA DEPORTIVA</li>
                        <li>CROSSFIT, TRX, GIMNASIO, SPORTBAR</li>
                        <li>ROOFTOP</li>

                    </ul>

                        </Col>
                    </Row>
                    <img src={flor} className="flor mx-auto" alt="Logo valenzza" />
                </Col>
 
          </Row>
      </Container>
    </Container>
  );
}

export default Proyecto;
