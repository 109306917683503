import React from 'react';
import './Footer.css';
import telefono from '../../assets/img/telefono.png';
import correo from '../../assets/img/correo.png';
import direccion from '../../assets/img/direccion.png';
import sitio from '../../assets/img/url.png';
import logo from '../../assets/img/logo-footer.png';
import valderrama from '../../assets/img/logo-valderrama.png';
import { Container, Row, Col } from 'react-bootstrap';
import Whatsapp from '../whatsapp/Whatsapp';

const Footer = (props) => {
    return (
    <Container fluid={true} className="footer padding0">
        <Whatsapp />
        <Row>
            <Col md="4" sm="6"  className="padding0">
                <img src={logo} alt="Logo Privilege" className="img-logo-footer" />
            </Col>
            <Col md="4"  sm="6" className="padding0 back-cafe cuna">
                <Row>
                    <Col md="12" className="redesfooter">
                        <a href="https://www.facebook.com/constructoravalderrama/" className="facebook" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-facebook"></i>
                        </a>
                        <a  href="https://www.youtube.com/watch?v=4hWTuqkbkes" className="youtube" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-youtube-play"></i>
                        </a>
                        <a  href="https://www.instagram.com/constructora_valderrama/?hl=es-la" className="instagram" target="_blank" rel="noopener noreferrer"> 
                            <i className="fa fa-instagram"></i>
                        </a>
                        <a  href="https://twitter.com/constvalderrama?lang=es" target="_blank" className="twitter" rel="noopener noreferrer">
                            <i className="fa fa-twitter"></i>
                        </a>
                    </Col>
                </Row>                      
                <Row>
                    <Col md="12">
                    <a href="https://constructoravalderrama.com" target="_blank" rel="noopener noreferrer"><img src={valderrama} alt="Logo Valderrama y Logo Peninsula" className="img-logo-footer" /></a>
                    </Col>
                </Row>
            </Col>
            <Col md="4" sm="12" className="padding0 back-cafe">
                <div className="square-footer">
                    <div>
                        <img src={telefono} alt="Teléfono" width="20" /> <a href="tel:+573013300667">+57 301 330 0667 </a> 
                    </div>
                    <div>
                        <img src={correo} alt="Correo" width="20" /> <a href="mailto:Contacto@constructoravalderrama.com.co">Contacto@constructoravalderrama.com.co</a>
                    </div>
                    <div>
                        <img src={direccion} alt="Dirección" width="20" /> <a href="https://www.google.com.co/maps/search/m+2.0+Anillo+vial,+v%C3%ADa+Floridablanca+-+Gir%C3%B3n/@7.0613639,-73.1124122,19z?hl=es-419" target="_blank" rel="noopener noreferrer">Km 2 Anillo vial, vía Floridablanca - Girón&nbsp;</a> 
                    </div>
                    <div>
                        <img src={sitio} alt="Sitio Web" width="20" /> <a href="https://constructoravalderrama.com" target="_blank" rel="noopener noreferrer">www.constructoravalderrama.com</a>
                    </div>                   
                </div>
            </Col>
        </Row>
    </Container>
  );
}

export default Footer;
