import React from 'react';
import './Whatsapp.css';
import whatsapp from '../../assets/img/whatsapp.png';

const Whatsapp = () => {
    return (
            <a className="whatsapp" href="https://api.whatsapp.com/send?phone=573013300667&text=Hola%2C%20deseo%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20proyecto%20VALVENTUS." target="_blank" rel="noopener noreferrer">
                <img src={whatsapp} alt="Whatsapp" />
            </a>
         );
}

export default Whatsapp;
