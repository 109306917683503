import React from 'react';
import './Top.css';
import telefono from '../../assets/img/telefono.png';
import correo from '../../assets/img/correo.png';
import direccion from '../../assets/img/direccion.png';
import flag from '../../assets/img/flag.png';
import logo from '../../assets/img/logo.png';
import { Container, Row, Col } from 'react-bootstrap';

const Top = (props) => {
    return (
    <Container fluid={true} className="transparent">
        <Row class="rtl">
            <Col md={3} className="hidden-sm">
            <img src={logo} className="logo" alt="logo" />
            </Col>
            <Col md={9} xs={12}>
                <Col md={12} sm={12} className="text-right padding0 texto-top">
                <span><img src={direccion} alt="Correo" width="20" /><a href="https://www.google.com.co/maps/search/m+2.0+Anillo+vial,+v%C3%ADa+Floridablanca+-+Gir%C3%B3n/@7.0613639,-73.1124122,19z?hl=es-419" target="_blank" rel="noopener noreferrer">Km 2 Anillo vial, vía Floridablanca - Girón&nbsp;</a> </span> 
                <span className="fore-white"> (<img src={flag} width="20" alt="Colombia" />+57) </span> 
                <span className="fore-white"><img src={telefono} alt="Teléfono" width="20" /> <a href="tel:+573013300667">301 330 0667 </a> &nbsp;</span>
                <span><img src={correo} alt="Correo" width="20" /> <a href="mailto:Contacto@constructoravalderrama.com.co">contacto@constructoravalderrama.com.co</a></span></Col>
                <Row>
                    <Col md={12} className="text-right redes padding0">
                    <a href="https://www.facebook.com/constructoravalderrama/" className="facebook" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-facebook"></i>
                        </a>
                        <a  href="https://www.youtube.com/watch?v=4hWTuqkbkes" className="youtube" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-youtube-play"></i>
                        </a>
                        <a  href="https://www.instagram.com/constructora_valderrama/?hl=es-la" className="instagram" target="_blank" rel="noopener noreferrer"> 
                            <i className="fa fa-instagram"></i>
                        </a>
                        <a  href="https://twitter.com/constvalderrama?lang=es" target="_blank" className="twitter" rel="noopener noreferrer">
                            <i className="fa fa-twitter"></i>
                        </a>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} className="hidden-md">
            <img src={logo} className="logo" alt="logo" />
            </Col>
        </Row>
    </Container>
  );
}

export default Top;
