import React from 'react';
import './Planos.css';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import leafLeft from '../../assets/img/left-leaf.png';
import sombra from '../../assets/img/sombra.png';

function importAll(r) {
    return r.keys().map(r);
}

const images = importAll(require.context('../../assets/img/plano/', false, /\.(png|jpe?g|svg)$/));

const Proyecto = (props) => {

    return (
        <Container fluid className="relative ptop60 padding0">
            <Tab.Container id="planos-tab" defaultActiveKey="a">
            <img src={leafLeft} className="leafLeft turndown" alt="hojaizquierda" />
            <Container className="mtop40">
                <Row>                
                    <Col md={12}>
                        <h2 className="title-seccion">Tipos de Áreas:</h2>
                        </Col>                  
                </Row>
                <Row className="mtop20">
                    <Col md={6} sm={12}>
                        <div className="torre-title text-center">TORRE 1</div>
                            <Nav>
                                <Nav.Item>
                                    <Nav.Link eventKey="a">TIPO A</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="b">TIPO B</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="c">TIPO C</Nav.Link>
                                </Nav.Item>
                            </Nav>
                    </Col>
                    <Col md={6} sm={12}> 
                        <div className="torre-title text-center">TORRE 2</div>
                        <Nav>
                            <Nav.Item>
                                <Nav.Link eventKey="a2">TIPO A</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="b2">TIPO B</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="c2">TIPO C</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="d2">TIPO D</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
                <Row >                    
                    <Col xs={12}>                        
                        <img src={sombra} alt="Sombra" className="img-fluid mx-auto turndown" />
                    </Col>
                </Row>
            
            <Row className="margin0 padding0">
                <Col xs={12} className="padding0">
                    <Tab.Content>
                        <Tab.Pane eventKey="a">
                            <Row>
                                <Col sm="12"  className="padding0 pleft">
                                    <img src={images[0]} alt="Tipo A" className="img-plano hidden-sm" />
                                    <img src={images[1]} alt="Tipo A" className="img-plano hidden-md" />
                                </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="b">
                            <Row>
                                <Col sm="12"  className="padding0 pleft">
                                    <img src={images[2]} alt="Tipo B" className="img-plano hidden-sm" />
                                    <img src={images[3]} alt="Tipo B" className="img-plano hidden-md" />
                                </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="c" >
                            <Row>
                                <Col sm="12"  className="padding0 pleft">
                                    <img src={images[4]} alt="Tipo C" className="img-plano hidden-sm" />
                                    <img src={images[5]} alt="Tipo C" className="img-plano hidden-md" />
                                </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="a2">
                            <Row>
                                <Col sm="12"  className="padding0 pleft">
                                    <img src={images[6]} alt="Tipo A 2" className="img-plano hidden-sm" />
                                    <img src={images[7]} alt="Tipo A 2" className="img-plano hidden-md" />
                                </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="b2">
                            <Row>
                                <Col sm="12"  className="padding0 pleft">
                                    <img src={images[8]} alt="Tipo B 2" className="img-plano hidden-sm" />
                                    <img src={images[9]} alt="Tipo B 2" className="img-plano hidden-md" />
                                </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="c2">
                            <Row>
                                <Col sm="12"  className="padding0 pleft">
                                    <img src={images[10]} alt="Tipo C 2" className="img-plano hidden-sm" />
                                    <img src={images[11]} alt="Tipo C 2" className="img-plano hidden-md" />
                                </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="d2">
                            <Row>
                                <Col sm="12"  className="padding0 pleft">
                                    <img src={images[12]} alt="Tipo D 2" className="img-plano hidden-sm" />
                                    <img src={images[13]} alt="Tipo D 2" className="img-plano hidden-md" />
                                </Col>
                            </Row>
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
            </Container>
            </Tab.Container>
            <Container>
                <Row className="mtop20">
                    <Col xs={12} className="disclaimer">
                        * Las imágenes y contenidos aquí publicados pueden no coincidir en su totalidad con la realidad de la construcción. Los materiales texturas, colores, equipamiento y mobiliario que se exhibe es solamente ilustrativo y para efecto de pre-ventas. Este material está destinado exclusivamente para la información y/o consulta de los potenciales compradores. Los cambios que el proyecto pueda tener son exclusividad de la constructora sin tener que dar previo aviso, siempre y cuando estos no afecten la calidad del producto final.
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Proyecto;
